import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/app.scss';
// import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import {createClient} from "@supabase/supabase-js";
import {SessionContextProvider} from "@supabase/auth-helpers-react";

const supabase = createClient(
      process.env.REACT_APP_SUPABASE_URL,
      process.env.REACT_APP_SUPABASE_ANON_KEY
    );

const router = createBrowserRouter([
  {
    path: "/",
    index: true,
    loader: ({ request }) => null,
    lazy: () => import("./pages/HomePage").then((module) => ({Component: module.default})),
  },
  {
    path: '/pricing/',
    loader: ({ request }) => null,
    lazy: () => import("./pages/PricingPage").then((module) => ({Component: module.default})),
  },
  {
    path: '/dashboard/',
    loader: ({ request }) => null,
    lazy: () => import("./pages/DashboardPage").then((module) => ({Component: module.default})),
  },
  {
    path: '/project/:project_id/:comment_id?/',
    loader: ({ request }) => null,
    lazy: () => import("./pages/ProjectPage").then((module) => ({Component: module.default})),
  },
  {
    path: '/join/',
    loader: ({ request }) => null,
    lazy: () => import("./pages/JoinOrganizationPage").then((module) => ({Component: module.default})),
  },
  {
    path: '*',
    loader: ({ request }) => {
      window.location = "/";
    },
    lazy: () => import("./pages/HomePage").then((module) => ({Component: module.default})),
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SessionContextProvider supabaseClient={supabase}>
    <RouterProvider router={router}/>
  </SessionContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();*/
